import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/functional-programming",
  "date": "2016-06-30",
  "title": "FUNCTIONAL PROGRAMMING",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "Abstract control flow and operations on data with functions in order to avoid side effects & reduce mutation of state"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Why Functional Programming?`}</h2>
    <p>{`Abstract control flow and operations on data with functions in order to:`}</p>
    <ul>
      <li parentName="ul">{`Avoid side effects`}</li>
      <li parentName="ul">{`Reduce mutation of state`}</li>
    </ul>
    <h2>{`Pure Functions`}</h2>
    <p>{`Pure functions are different from impure functions because:`}</p>
    <ul>
      <li parentName="ul">{`It depends only on the input provided—and not on any hidden or external state that may change as a function’s evaluation proceeds or between function calls.`}</li>
      <li parentName="ul">{`It does not inflict changes beyond its scope (like modifying a global object or a parameter reference).`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/images/2016-06-30-functional-programming/pure-functions.png",
        "alt": "Pure Function Equation"
      }}></img></p>
    <h3>{`Referential Transparency`}</h3>
    <p>{`If a function consistently yields the same result on the same input, it is said to be `}<inlineCode parentName="p">{`Referentially Transparent`}</inlineCode>{` (RT).`}</p>
    <h3>{`Avoiding Loops`}</h3>
    <p>{`Pure functional programs don’t mutate variables (like a loop counter) after they’ve been initialized. So avoid while, and do-while. Instead, we will take advantage of high-order functions—like: forEach, map, reduce, and filter—to abstract iteration schemes, which also help us remove a few if-else conditionals while we’re at it.`}</p>
    <h3>{`forEach`}</h3>
    <p>{`The forEach loop has the following advantages:`}</p>
    <ul>
      <li parentName="ul">{`Declarative and easier to understand`}</li>
      <li parentName="ul">{`Body being inside a function, it properly binds each value into the callback’s parameter.`}</li>
      <li parentName="ul">{`Because of No. 2, it avoids scoping bugs.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`[80, 90, 100].forEach(function(value) {
      console.log(value);
});
// 80, 90, 100
`}</code></pre>
    <h3>{`map`}</h3>
    <p>{`The map function has the following advantages:`}</p>
    <ul>
      <li parentName="ul">{`Transforms an array contents into an array of similar length as the original.`}</li>
      <li parentName="ul">{`Keeps the original array intact.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`['a', 'b', 'c', 'd'].map(function(letter) {
     return letter.toUpperCase();
}); 
// ["A", "B", "C", "D"]
`}</code></pre>
    <h3>{`filter`}</h3>
    <p>{`The filter function has the following advantages:`}</p>
    <ul>
      <li parentName="ul">{`Transforms an array by mapping a predicate function(a function with a boolean return value) on to each element.`}</li>
      <li parentName="ul">{`The returned array may contain a subset of the original values returned by the predicate.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`['a', 'b', 'c', 'd'].map(function(letter) {
     return letter.toUpperCase();
});
// ["A", "B", "C", "D"]
`}</code></pre>
    <h3>{`reduce`}</h3>
    <p>{`The reduce function has the following advantages:`}</p>
    <p>{`Can be used to fold or gather the contents of an array by compressing it into a single value.
Each successive invocation is supplied the return value of the previous.
Seems a combination of map and filter.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`[1, 2, 3, 4].reduce(function(total, num) {
     return total + num;
});
// 10
`}</code></pre>
    <h2>{`Function Abstractions`}</h2>
    <p>{`Functional programming provides very powerful abstractions that allow you to create functions from the definition of other functions or to augment the evaluation of existing functions.`}</p>
    <h3>{`currying`}</h3>
    <p>{`Currying is a technique that converts a multivariable function into a step-wise sequence of unary functions.`}</p>
    <p>{`In other words, a function with parameters: f(a,b,c) is internally augmented to one that works on parameters one at a time: f(a) -> f(b) -> f(c)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var name = function(first) {
     return function(last) {
          console.log(first, last);
     }
};
name(‘John’)(’Smith’);  // John Smith
`}</code></pre>
    <h3>{`Composition`}</h3>
    <p>{`Composition is the process used to group together the execution of simpler functions.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var compose = function () {
  var fns = arguments;
 
  return function (result) {
    for (var i = fns.length - 1; i > -1; i--) {
      result = fns[i].call(this, result);
    }
 
    return result;
  };
};
var add = function(n) { return n + n; };
var multiply = function(n) { return n * n; };
 
compose(add, multiply)(4); // 32
`}</code></pre>
    <h2>{`Functional Data Types`}</h2>
    <p>{`Currying and composition provide abstractions over functions—the drivers for behavior in your application.`}</p>
    <p>{`Functional data types like functors and monads provide abstractions over the data.`}</p>
    <p>{`Functional data types part from the notion of mapping functions onto containers.`}</p>
    <h2>{`Containerizing`}</h2>
    <p>{`The idea behind containerizing is very simple but has far-reaching applications. The goal is to place values in a container. By doing this, we hope to create a logical barrier that promotes transforming values immutably and side-effect free.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function getWords(val) {
     return val.split(' ');
}
  
function getSize(array) {
     return array.length;
}
  
function Wrapper(val) {
     this.val = val;
}
 
Wrapper.prototype.map = function(f) {return f(this.val);};
 
var wrap = (val) => new Wrapper(val);
  
wrap('hello world').map(getWords).map(getSize); // [5, 5]
`}</code></pre>
    <p>{`In ES6, we can do the same like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const getWords = val => val.split(' ');
   
const getSize = array => array.length;
   
class Wrapper {
  constructor(val) {
    this.val = val;
  }
   
  map(f) {
    return f(this.val);
  }
}
  
var wrap = (val) => new Wrapper(val);
   
wrap('hello world').map(getWords).map(getSize);
`}</code></pre>
    <h2>{`Functors`}</h2>
    <p>{`The array map above is a functor since you can map a function onto its elements and the result is again an array.`}</p>
    <p>{`A functor is nothing more than a type that implements a mapping function with the purpose of lifting values into a container.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`F.prototype.map = function(f){
 
     return F.of(f(this.val))
}
`}</code></pre>
    <p>{`The function `}<inlineCode parentName="p">{`F.of`}</inlineCode>{` is a way to instantiate the data type without having to call new.`}</p>
    <p>{`Functors are important because they introduce the idea of a function “reaching” into the container, modifying it, and placing the value “back into” it.`}</p>
    <h2>{`Monads`}</h2>
    <p>{`Monads are data types similar to functors, but they also define the rules by which values are contained within them. The monad type, as such, is an abstract data type with the following interface:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Interface`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Bind function`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Used for chaining operations on monadic values, called map`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Join operation`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Used to flatten layers of monadic structures into one. Important when used in the composition of functions that return monadic types`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Type constructor`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Used for inserting a value into a monadic structure. Typically called of or fromNullable`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Unit function`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Used for creating monadic types`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Monadic types are concrete implementations of this interface. In practical programming problems, monadic types are frequently seen to abstract and consolidate null checks and error handling. The two most frequently used are the Maybe and Either types.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      